<template>

    <!-- <div v-show="isLoading" class="flex space-x-2 justify-center items-center bg-white h-screen dark:invert">
        <span class="sr-only">Loading...</span>
        <div class="h-8 w-8 bg-black rounded-full animate-bounce" :style="{ 'animation-delay': '-0.3s' }"></div>
        <div class="h-8 w-8 bg-black rounded-full animate-bounce" :style="{ 'animation-delay': '-0.15s' }"></div>
        <div class="h-8 w-8 bg-black rounded-full animate-bounce"></div>
    </div> -->


    <div v-if="isLoading" class="fixed inset-0 bg-black bg-opacity-60 z-50 flex items-center justify-center">

        <div v-show="isLoading" class="flex space-x-2 justify-center items-center   dark:invert">
            <span class="sr-only">Loading...</span>
            <div class="h-4 w-4 bg-black rounded-full animate-bounce" :style="{ 'animation-delay': '-0.3s' }"></div>
            <div class="h-4 w-4 bg-black rounded-full animate-bounce" :style="{ 'animation-delay': '-0.15s' }"></div>
            <div class="h-4 w-4 bg-black rounded-full animate-bounce"></div>
        </div>

        <!-- <div class="flex items-center">
            <span class="text-3xl text-white mr-4">Loading</span>
            
            <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
            </svg>
          
        </div> -->
    </div>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>
/* Optional: Additional styling for the loading overlay */
</style>