import { createApp } from 'vue'
import App from './App.vue'
import router from './router'; // 导入路由设置
import '@/style.css'
//in your `main.js` file
 
import '@fortawesome/fontawesome-free/css/all.min.css';
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    next()
  })
const app = createApp(App);
app.use(router);

app.mount('#app')
