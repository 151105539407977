<template>
  <section class="px-0 lg:px-0 pt-28 mx-auto">
    <img src="@/assets/mmbuy/hz/hz01.jpg" alt="" class=" w-full h-auto">
  </section>

  <div class="py-8  mx-auto text-center  ">

    <!-- 《中国全球商品买卖网》招商合作进行中 start -->
    <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto p-4">
      <h1 class=" text-4xl font-bold text-primary">《中国全球商品买卖网》招商合作进行中</h1>
      <p class="mt-4 mx-auto index-desc lg:py-8 text-left">
        我们创建的《中国全球商品买卖网》（供需）大平台，是一个创新的、多功能的、综合服务的，世界级的，为全球各个国家、各级政府、各类企业和个人提供综合服务的互联网大平台；是一个为政府、企业、个人解决商海里问题和困难的大平台；是一个万亿级的大平台！平台的想象空间、创意空间、市场空间、实战空间、发展空间无限美好！
      </p>
    </section>
    <!-- 《中国全球商品买卖网》招商合作进行中 end -->


    <!-- 投资合作的优势start -->
    <div class=" bg-gray-100 ">
      <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto  p-4">
        <h2 class=" text-4xl font-bold text-primary mb-8 mt-8">投资合作优势</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8 mb-4 items-center justify-center">
          <div class="bg-white h-[400px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-10 w-20" src="@/assets/mmbuy/hz/hz10.png">
            <div class=" font-bold mb-5 text-3xl">创新的思想理论支撑</div>
            <div class="  text-gray-500 text-center text-2xl h-[100px]">
              在总结国内国际互联网平台的基础上，进行了思想理论与技术等创新
            </div>
          </div>
          <div class="bg-white h-[400px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-10 w-20" src="@/assets/mmbuy/hz/hz11.png">
            <div class=" font-bold mb-5 text-3xl">创新的服务功能</div>
            <div class="  text-gray-500 text-center text-2xl h-[100px]">
              具有全球商品买卖、全球资产买卖、全球找工作、全球招商引资等
            </div>
          </div>

          <div class="bg-white h-[400px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-10 w-20" src="@/assets/mmbuy/hz/hz12.png">
            <div class=" font-bold mb-5 text-3xl">创新的运营模式</div>
            <div class="  text-gray-500 text-center text-2xl h-[100px]">
              通过线上、线下双轮驱动，实现“买全球、卖全球”
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 pb-4  items-center justify-center">
          <div class="bg-white h-[400px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-10 w-20" src="@/assets/mmbuy/hz/hz13.png">
            <div class=" font-bold mb-5 text-3xl">顶级的专业团队</div>
            <div class="  text-gray-500 text-center text-2xl h-[100px]">
              顶级的技术团队开发，顶级的市场团队运营
            </div>
          </div>
          <div class="bg-white h-[400px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-10 w-20" src="@/assets/mmbuy/hz/hz14.png">
            <div class=" font-bold mb-5 text-3xl">利润收益可观</div>
            <div class="  text-gray-500 text-center text-2xl h-[100px]">
              规划上市，投资回报翻倍
            </div>
          </div>

          <div class="bg-white h-[400px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-10 w-20" src="@/assets/mmbuy/hz/hz15.png">
            <div class=" font-bold mb-5 text-3xl">利润分配</div>
            <div class="  text-gray-500 text-center text-2xl h-[100px]">
              公司按照投资人的投资额占股比例，每年年末向股东分配一次利润
            </div>
          </div>
        </div>
      </section>

    </div>

    <!-- 投资合作的优势end -->



    <!-- 代理商的条件  start-->
    
      <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto  p-4 ">
        <h2 class=" text-4xl font-bold text-primary mb-8 mt-8">代理商的条件</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8 mb-4 items-center justify-center">
          <div class="bg-white h-[450px]    flex flex-col   border-2 border-gray-100">
            <img class=" mb-8 " src="@/assets/mmbuy/hz/hz20.png">
            <div class=" font-bold text-left text-2xl px-10 mb-6">共同成就一份事业</div>
            <div class="  text-gray-500 text-left text-xl h-[100px] px-10">
              认同公司经营理念、企业文化及品牌价值。对品牌充满信心，有与公司共同发展的强烈愿望。
            </div>
          </div>

          <div class="bg-white h-[450px]    flex flex-col   border-2 border-gray-100">
            <img class=" mb-8 " src="@/assets/mmbuy/hz/hz21.png">
            <div class=" font-bold text-left text-2xl px-10 mb-6">诚实可信、品行端正</div>
            <div class="  text-gray-500 text-left text-xl h-[100px] px-10">
              积极配合公司的市场运作，具有契约精神、遵纪守法。
            </div>
          </div>

          <div class="bg-white h-[450px]    flex flex-col   border-2 border-gray-100">
            <img class=" mb-8 " src="@/assets/mmbuy/hz/hz22.png">
            <div class=" font-bold text-left text-2xl px-10 mb-6">综合能力强</div>
            <div class="  text-gray-500 text-left text-xl h-[100px] px-10">
              个人的综合能力强，有一定经济实力。
            </div>
          </div>
        </div>
      </section>
    <!-- 代理商（股东、经理）交费标准  end-->


    <!-- 代理商（股东、经理）交付标准  start-->
    <div class=" bg-gray-100 ">
      <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto  p-4">
        <h2 class=" text-4xl font-bold text-primary mb-8 mt-8">代理商（股东、经理）交费标准</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-32 mt-8  items-center justify-center">
          <div class=" h-[100px]     flex flex-col items-center justify-center">
            <div class="  text-gray-500 text-center text-2xl h-[100px]">
              省级代理商（终身）
            </div>
            <div class=" font-bold mb-5 text-3xl">200万</div>
          </div>

          <div class=" h-[100px]     flex flex-col items-center justify-center">
            <div class="  text-gray-500 text-center text-2xl h-[100px]">
              市级代理商（终身）
            </div>
            <div class=" font-bold mb-5 text-3xl">50万</div>
          </div>

          <div class=" h-[100px]     flex flex-col items-center justify-center">
            <div class="  text-gray-500 text-center text-2xl h-[100px]">
              县（区）代理商（终身）
            </div>
            <div class=" font-bold mb-5 text-3xl">20万</div>
          </div>

        </div>

        <div class="  text-gray-500 text-left text-2xl  mt-4 mb-4 ">
          注：国外代理商参照国内代理商标准执行。
        </div>


      </section>

    </div>

    <!-- 代理商（股东、经理）交费标准  end-->




    <!-- 代理商的条件 start -->

    <!-- <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto  p-4">
      <h2 class=" text-4xl font-bold text-primary mb-8 mt-8 ">代理商的条件</h2>
      <div class="grid grid-cols-1 md:grid-cols-4 gap-2 mt-8  items-center justify-center">
        <div class="bg-F8F7FF h-[300px] p-8 m-1   flex flex-col pt-15 ">
          <div class=" font-bold mb-5 text-2xl h-14">共同成就一份事业</div>
          <div class="  text-gray-500 text-left text-xl">
            认同公司经营理念、企业文化及品牌价值。对品牌充满信心，有与公司共同发展的强烈愿望。
          </div>
        </div>
        <div class="bg-FFFAEF h-[300px] p-8 m-1   flex flex-col pt-15">
          <div class=" font-bold mb-5 text-2xl h-14">诚实可信、品行端正</div>
          <div class="  text-gray-500 text-left text-xl">
            积极配合公司的市场运作，具有契约精神、遵纪守法。
          </div>
        </div>
        <div class="bg-FFF8F8 h-[300px] p-8 m-1   flex flex-col pt-15">
          <div class=" font-bold mb-5 text-2xl h-14">综合能力强</div>
          <div class="  text-gray-500 text-left text-xl">
            个人的综合能力强，有一定经济实力。
          </div>
        </div>

        <div class="bg-F1FDFF h-[300px] p-8 m-1   flex flex-col pt-15">
          <div class=" font-bold mb-5 text-2xl h-14">代理商要求</div>
          <div class="  text-gray-500 text-left text-xl">
            省级代理商：100万<br />
            市级代理商：50万<br />
            县(区)代理商：20万<br />
          </div>
        </div>
      </div>
    </section> -->

    <!-- 代理商的设立 start -->
    <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto p-4  ">
      <h1 class=" text-4xl font-bold text-primary mb-8 mt-8">代理商的设立</h1>


      <!-- <ul class="red-dot-list1 list-disc  space-y-4  ">
          <li>国内每个省、市、县（区）设立一个代理商。</li>
          <li>若全球每个国家设立一个代理商。</li>
        </ul> -->

      <ul class="list-disc text-left space-y-4 text-2xl m-8">
        <li>国内每个省、市、县（区）设立一个代理商（股东、经理）。</li>
        <li>全球每个国家设立N个代理商（股东、经理）。</li>
        <!-- ... -->
      </ul>

    </section>
    <!-- 代理商的设立 end -->


    <!-- 代理商的回报 start -->
    <div  class="bg-FFF5F5">

      <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto  p-4 ">
        <h2 class=" text-primary text-4xl font-bold  mb-8 mt-8">代理商的回报</h2>
        <!-- <div class="text-left text-primary mt-4 mx-auto index-desc lg:py-8 leading-normal">
          公司海外市场正在逐步开发中，请有合作意向的伙伴联系我们，公司将结合海外市场开发进度及规划进行评估，非常感谢您对《中国全球商品买卖网》的认可！
        </div> -->


        <div class="grid grid-cols-1 md:grid-cols-3 gap-32 mt-8  items-center justify-center">
          <div class="  m-10  h-16 flex flex-col pt-15  border-l-2 border-red-700 justify-center ">
            <div class=" pl-3  text-gray-500 text-left text-xl ">
              本区域销售产生的利润10%奖励给各级代理商
            </div>
          </div>

          <div class="  m-10  h-16 flex flex-col pt-15  border-l-2 border-red-700 justify-center ">
            <div class=" pl-3  text-gray-500 text-left text-xl ">

              每年年末按投资人的投资额占股比例给予奖励
            </div>
          </div>

          <div class="  m-10  h-16 flex flex-col pt-15  border-l-2 border-red-700 justify-center ">
            <div class=" pl-3  text-gray-500 text-left text-xl ">

              公司上市按股东占股比例分红
            </div>
          </div>


        </div>

      </section>
    </div>
    <!-- 代理商的回报 end -->


    <!-- 诚招海外代理商start -->

    <!-- <picture>
            <source srcset="@/assets/mmbuy/hz/hz06.png" class="w-full h-auto" >
            <img src="@/assets/mmbuy/hz/hz06.png" alt="" class=" w-full h-auto">
          </picture> -->

    <!-- 诚招海外代理商end -->

    <section class="text-center max-w-screen-1200  mx-auto p-4 mt-10">
      <div class="mt-4 max-w-screen-xl mx-auto flex flex-col lg:flex-row lg:items-center">
        <div class="lg:w-2/3 lg:mt-0 text-left hz-phone">
          <p class="text-2xl font-bold">联系人：</p>
          <p class="font-bold text-2xl mt-2">李先生 18624266888 / 李女士 13842804200</p>
          <p class="hz-phone_p text-xl mt-3">如果您对我们感兴趣，请联系我们，期待您的加入！</p>

        </div>
        <div class="lg:w-1/3 lg:mt-0 text-center">
          <div class="flex space-x-16">
            <div class="border border-red-700 rounded-md p-2 text-center">
              <div class="w-36 h-36 mx-auto mb-2">
                <img src="@/assets/mmbuy/hz/hz08.png" alt="">


              </div>
              <p class="text-gray-700">添加企业微信</p>
            </div>
            <!-- <div class="border border-red-600 rounded-md p-2 text-center">
                <div class="w-36 h-36 bg-gray-300 mx-auto mb-2"></div>
                <p class="text-gray-700">添加好友</p>
              </div> -->
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>

</script>

<style scoped>
.hz-phone_p {
  font-family: Microsoft YaHei;
  font-size: 20px;
  line-height: 46px;
  text-align: left;
  color: #444444;
}

.hz-phone_last_p {
  font-family: Microsoft YaHei;
  font-size: 40px;
  line-height: 64px;
  text-align: left;
  color: #444444;
}

.coodesc p {
  font-weight: 400;
  line-height: 46px;
  color: #444444;
}

.red-dot-list1 li {
  display: flex;
  font-family: Microsoft YaHei;
  font-size: 24px;
}

.red-dot-list1 li::before {
  content: '•';
  /* 使用红点 */
  color: #444444;
  /* 设置红点颜色 */
  font-size: 1.2em;
  /* 设置红点大小 */
  margin-right: 0.5em;
  /* 设置红点和文本之间的间距 */
}


.my-red-text {
  color: #BF242A;
}
</style>
