<template>
  <!-- 示例内容 -->
  <footer class="w-full bg-C272A6F text-white py-10 ">
    <div class="max-w-screen-1200 mx-auto flex flex-col md:flex-row justify-between items-center px-4">
      <!-- 左侧内容 -->
      <div class="text-center md:text-left mb-4 md:mb-0 footer ">
        <router-link to="/" role="menuitem">
          <img src="@/assets/mmbuy/foot-logo.png" alt="Logo" class="h-[46px] inline-block mb-5 ">
          <!-- <span alt="Logo" class="h-[6px] w-[46px] inline-block  "></span> -->

          <img src="@/assets/mmbuy/foot-logo1.png" alt="Logo" class="h-[46px] inline-block mb-5 md:ml-12 ">
        </router-link>

        <p class="text-sm text-white">联系电话：18624266888（李先生） / 13842804200（李女士）</p>
        <p class="text-sm text-white">联系地址：辽宁省大连市沙河口区富鸿国际大厦A座13层</p>
      </div>
      <!-- 右侧内容 -->
      <div class="flex items-center">
        <!-- <div class="text-center mx-4">
          <img src="https://via.placeholder.com/80.png?text=QR1" alt="微信公众号" class="w-20 h-20 mb-2">
          <p class="text-sm">微信公众号</p>
        </div> -->
        <div class="text-center mx-4">
          <img src="@/assets/mmbuy/foot-download.png" alt="扫码下载APP" class="w-20 h-20 mb-2">
          <p class="text-sm text-white">扫码下载APP</p>
        </div>
        <!--        <div class="mx-4">-->
        <!--          <button class="bg-red-600 text-white p-2 rounded-md flex items-center justify-center w-10 h-10">-->
        <!--            <span>&uarr;</span>-->
        <!--          </button>-->
        <!--        </div>-->
      </div>
    </div>

    <!-- <div class="border-b border-gray-200 mt-6"></div> -->

    <div class="mt-4 md:mt-12 max-w-screen-1200 mx-auto text-left text-sm px-4 text-white">©2024 中国全球商品买卖网&二十四小时买卖网版权所有 <a  
          href="http://www.beian.miit.gov.cn"> 备案号：辽ICP备2024033249号 辽ICP备2024033249号-1</a></div>

    <!-- <div class="footer-bottom max-w-screen-1200 mx-auto text-left text-sm px-4">
      ©2024 中国全球商品买卖网&二十四小时买卖网版权所有
      <a class="record-link" target="_blank" href="http://www.beian.miit.gov.cn"> 备案号：辽ICP备2024033249号
        辽ICP备2024033249号-1</a>
    </div> -->


  </footer>







</template>

<script>
export default {
}
</script>

<style>
 
</style>
