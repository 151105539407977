<template>
  <!-- Main section //只在移动端显示start -->
  <!-- <section class="pt-28 mx-auto block md:hidden lg:px-0">
  <img src="@/assets/mmbuy/banner/banner_m.png" alt="" class="w-full h-auto">
</section> -->


  <!-- 只在pc端显示 -->
  <section class="  px-0 lg:px-0 pt-28 mx-auto">
    <img src="@/assets/mmbuy/banner/banner01.png" alt="" class="w-full h-auto ">
  </section>

  <!-- 只在移动设备上显示 -->
  <!-- <div class="block md:hidden pt-28 mx-auto">
      <img src="@/assets/mmbuy/banner/banner_m.png" alt="Mobile Banner" class="w-full h-auto">
    </div> -->

  <!-- 只在桌面设备上显示 -->
  <!-- <div class="hidden md:block pt-28 mx-auto">
      <img src="@/assets/mmbuy/banner/banner01.png" alt="Desktop Banner" class="w-full h-auto">
    </div> -->

  <!-- <section class="  px-0 lg:px-0 pt-28 mx-auto">

    <img src="@/assets/mmbuy/banner/banner_m.png" alt="" class="block md:hidden w-full h-auto">
    <img src="@/assets/mmbuy/banner/banner01.png" alt="" class="hidden md:block  w-full h-auto ">
  </section> -->


  <div class="lg:py-20 md:py-12 px-6 max-w-screen-xl mx-auto text-center">
    <div class="max-w-screen-1200">
      <!-- 简介部分 -->
      <section class="text-center py-8">
        <h1 class=" text-4xl font-bold text-primary">《中国全球商品买卖网》简介</h1>
        <p class="mt-4 mx-auto index-desc lg:py-8 text-left leading-loose">
          我们创建的《中国全球商品买卖网》（供需）大平台，是一个创新的、多功能的、综合服务的、世界级的，为全球各个国家、各级政府、各类企业和个人提供综合服务的互联网大平台；是一个为政府、企业、个人解决商海里问题和困难的大平台；是一个万亿级的大平台！平台的想象空间、创意空间、设计空间、市场空间、实战空间、未来发展空间无限美好！
        </p>
        <div class="mt-4 flex flex-col lg:flex-row lg:justify-center lg:gap-0">
          <img src="@/assets/mmbuy/index/Mask01.png" alt="简介图片" class="mx-auto mb-4 lg:mb-0">
          <img src="@/assets/mmbuy/index/Mask02.png" alt="简介图片" class="mx-auto">
        </div>
      </section>


      <!-- 服务功能部分 -->
      <section class="text-center py-8">
        <h1 class=" text-4xl font-bold text-primary">《中国全球商品买卖网》的服务功能</h1>
        <div class="mt-4 max-w-screen-xl mx-auto flex flex-col lg:flex-row lg:items-center lg:gap-4 lg:py-4">
          <div class="lg:w-1/2 mt-4 lg:mt-0 ">
            <img src="@/assets/mmbuy/index/Mask03.png" alt="" class="">
          </div>
          <div class="lg:w-1/2 p mt-10 lg:mt-0 lg:px-10 px-10 text-left index-serve m-auto lg:-ml-20">
            <ul class="list-none list-inside space-y-6 text-indent">
              <li>一、全球商品买卖</li>
              <li>二、全球资产买卖</li>
              <li>三、全球人才、劳动力找工作</li>
              <li>四、全球做广告，广告做全球</li>
              <li>五、全球招商引资，为政府、企业对接项目</li>
              <li>六、全球商协会交流合作</li>
              <li>七、全球“网红之家”</li>
              <li>八、全球互帮互助爱心平台</li>
              <li>九、全球产品品牌营销策划</li>
              <li>十、全球企业家交友</li>
              <!-- 其他功能列表项 -->
            </ul>
          </div>
        </div>
      </section>

      <!-- 目标部分 -->
      <section class="text-center py-8">
        <h2 class=" text-4xl font-bold text-primary">《中国全球商品买卖网》的目标</h2>
        <div class="mt-4 max-w-screen-xl mx-auto flex flex-col lg:flex-row lg:items-center lg:gap-8 lg:py-8">
          <div class="lg:w-1/2 mt-10 lg:mt-0 lg:px-0 px-10 text-left index-serve m-auto">
            <ul class="red-dot-list list-outside space-y-6 ">
              <li>打造成，“买全球、卖全球”，世界知名品牌</li>
              <li>打造成，万亿级营销额的大平台</li>
              <li>打造成，千亿级利税额的公司</li>
              <li>打造成，千亿级市值的上市公司</li>
              <li>打造成，全球50%以上的人口使用这个大平台</li>
              <li>打造成，让全球人参与、为全球人服务的大平台</li>
              <li>打造成，百年科技企业</li>

              <li>打造成，推动东北经济、推动中国经济、推动世界经济快速发展的新引擎 </li>
              <li>平台落户东北，填补东北三省没有大型互联网电商大平台的空白，提升东北三省在中国、在世界的知名度、影响力和地区价值</li>


            </ul>
          </div>
          <div class="lg:w-1/2 mt-4 lg:mt-0 flex justify-end">
            <img src="@/assets/mmbuy/index/Mask04.png" alt="" class="">
          </div>
        </div>
      </section>

      <!-- 意义部分 -->
      <section class="text-center py-8">
        <h2 class=" text-4xl font-bold text-primary">《中国全球商品买卖网》的意义</h2>
        <div class="max-w-screen-xl mx-auto flex flex-col lg:flex-row lg:items-center lg:gap-8 lg:py-8 mt-8">
          <div class="lg:w-2/5 mt-4 lg:mt-0">
            <img src="@/assets/mmbuy/index/Mask05.png" alt="" class="h-lg">
          </div>
          <!-- <div class="lg:w-1/2 mt-10 lg:mt-0 lg:px-0 px-10 text-left index-serve m-auto "> -->

          <div
            class="lg:w-3/5 mt-10 lg:mt-0 lg:px-0 px-10 text-left index-serve  items-center justify-center lg:h-full">



            <ul class="red-dot-list list-outside space-y-14">
              <li>为习总书记提出的“一带一路”建设，插上了互联网电商科技的翅膀！</li>
              <li>为落实习总书记提出的“网络强国”、“新质生产力”思想理论和战略目标，贡献我们的力量！</li>
              <li>为东北经济、为中国经济、为世界经济的快速发展注入新动能、带来新活力，取得新成就！</li>
              <li>
                从全球来讲，有利于世界国与国之间、企业与企业之间、人与人之间的交流、合作；有利于世界人与人之间建立深厚的“感情”；有利于建设和平、和谐、美好的世界！</li>
            </ul>


          </div>
        </div>
      </section>

      <!-- 喜报部分 -->
      <section class="text-center py-8 ">
        <div class="mt-4 mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div class="text-white ">
            <img class="rounded-lg" src="@/assets/mmbuy/index/Group07.png">
          </div>
          <div class="text-white ">
            <img class="rounded-lg" src="@/assets/mmbuy/index/Group08.png">
          </div>
        </div>
      </section>


      <!-- 创新和实践部分 start-->
      <section class="text-center py-8">
        <h1 class=" text-4xl font-bold text-primary">《中国全球商品买卖网》全方位的创新和实践</h1>
        <h2 class=" text-3xl font-bold text-left mt-12 leading-relaxed">
          一、《中国全球商品买卖网》总结中国和世界各国互联网电商平台的经验，在思想理论等方面，进行的创新，价值无限</h2>
        <p class=" mx-auto index-desc lg:py-8 text-left leading-loose">
          我们将总结国内、国际互联网电商平台众家之长基础上，从思想理论、技术研发、服务功能、运营服务等方面，进行全方位再创新、再创造！将《中国全球商品买卖网》建设成为世界级的互联网（供需）大平台！做到平台价值无与伦比！从而服务全世界，使各个国家、各级政府、各类企业、个人都能参与和使用这个互联网（供需）大平台！
        </p>
        <div class="mt-4 flex flex-col lg:flex-row lg:justify-center lg:gap-0">
          <img src="@/assets/mmbuy/index/Mask07.png" alt="简介图片" class="mx-auto mb-4 lg:mb-0">
          <img src="@/assets/mmbuy/index/Mask08.png" alt="简介图片" class="mx-auto">
        </div>

        <h2 class=" text-3xl font-bold text-left mt-12 leading-relaxed">二、《中国全球商品买卖网》名称的创新，节省了无数的广告费、价值无限</h2>
        <p class=" mx-auto index-desc pt-8 text-left leading-loose">
          我们创意的《中国全球商品买卖网》这个名称，通俗易懂，本身就是一句举世无双，让世人过目难忘的广告词！
        </p>
        <p class=" mx-auto index-desc  text-left leading-loose">
          这个平台的名称，全世界无论是白皮肤人、黑皮肤人、黄皮肤人，一看就懂！一看就能记住！一看就想了解和使用这个世界级的大平台！
        </p>


        <h2 class=" text-3xl font-bold text-left mt-16 leading-relaxed">三、《中国全球商品买卖网》LOGO的创新，节省了无数的广告费、价值无限</h2>
        <p class=" mx-auto index-desc pt-8 text-left leading-loose">
          我们创意的LOGO，计划请三位世界级的名人：一是白皮肤人，二是黑皮肤人，三是黄皮肤人，他（她）们都是倡导世界和平，倡导人间大爱的名人。三位名人手拉手、肩并肩、面带笑容的LOGO图片。
        </p>
        <p class=" mx-auto index-desc  text-left leading-loose">
          我们的平台不需要花钱做广告宣传，省去了无数的广告宣传费，待平台全部上线，我们相信世界各大媒体都会争相宣传报道《中国全球商品买卖网》，全世界大多数人都会成为我们平台的“粉丝”！
        </p>


        <h2 class=" text-3xl font-bold text-left mt-16 leading-relaxed">四、《中国全球商品买卖网》经营模式的创新，价值无限</h2>
        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          创建了《中国全球商品买卖网》电商服务部
        </p>
        <p class=" bg-gray-100 mx-auto index-desc text-xl text-left leading-loose pl-5 pr-5 pt-5">
          世界各个国家、企业、个人的工业产品、农业产品、服务业产品、科技产品、文化旅游产品等，均在《中国全球商品买卖网》的线上平台展示销售；同时创建线下旗舰店展示销售产品。实现线上、线下双轮驱动。
        </p>

        <p class=" bg-gray-100 mx-auto index-desc text-xl text-left leading-loose pl-5 pr-5">
          （1）线上：互联网电商平台+短视频+直播。
        </p>
        <p class=" bg-gray-100 mx-auto index-desc text-xl text-left leading-loose pl-5 pr-5">

          （2）线下：建设实体旗舰店，一期每个省会城市建设一家旗舰店，未来全国发展加盟店一万家，全球发展加盟店百万家！
        </p>
        <p class=" bg-gray-100 mx-auto index-desc text-xl text-left leading-loose pl-5 pr-5 pb-5">

          通过线上线下联动，将中国产品推向世界！将世界产品引进中国！将各个国家的产品推向世界！将《中国全球商品买卖网》做成永不落幕的展会，永不沉没的航母！实现“买全球、卖全球”！
        </p>


        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          创建了《中国全球商品买卖网》资产买卖服务部
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose p-5 ">
          为买卖工厂、矿山、公司等资产交易服务。
        </p>


        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          创建了《中国全球商品买卖网》全球人力资源服务部
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose p-5 ">

          帮助全球人才、劳动力找工作。实现人才、劳动力全球流动，解决找工作难、招工难的问题。
        </p>

        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          创建了《中国全球商品买卖网》广告服务部
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose p-5 ">

          为政府、企业、个人，全球做广告！广告做全球！
        </p>

        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          创建了《中国全球商品买卖网》招商引资服务部
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose p-5 ">
          面向全球、面向世界，为各级政府、各类企业招商引资提供服务。
        </p>

        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          创建了《中国全球商品买卖网》全球商协会组织和企业入驻平台服务部
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose p-5 ">
          平台设立全球商协会专区，为商协会和会员企业提供信息交流、资源共享等全方位服务。
        </p>

        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          创建了《中国全球商品买卖网》网红国际协会服务部
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose p-5 ">
          为全球网红服务，为全球企业、个人带货赋能。
        </p>

        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          创建了《中国全球商品买卖网》全球互帮互助爱心服务部
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose p-5 ">
          为全球政府、企业、个人献爱心服务。
        </p>

        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          创建了《中国全球商品买卖网》全球产品品牌营销策划部
        </p>

        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          创建了《中国全球商品买卖网》全球企业家交友部
        </p>

        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          《中国全球商品买卖网》私域和公域两种运营模式，双向同时运营
        </p>

        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          《中国全球商品买卖网》邀请全球各个国家、各级政府、各类企业、个人入驻平台
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose p-5 ">

          设立各个国家展馆、各级政府展馆、各类企业展厅、个人展厅，宣传推销自我。
        </p>

        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          《中国全球商品买卖网》实行会员制模式：设立政府、企业、个人三种会员类别
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose p-5 ">

          会员可参资入股，按照投资额比例给予股份。
        </p>

        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          《中国全球商品买卖网》设立国内各省、市、县（区）代理商（股东、经理）
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose p-5 ">

          各地代理商负责运营本区域工作。
        </p>

        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          《中国全球商品买卖网》设立全球各个国家代理商（股东、经理）
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose p-5 ">

          代理商负责运营本国本区域工作，负责同中国总部对接工作。
        </p>


        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          《中国全球商品买卖网》将开发生产自己公司的产品，全球销售
        </p>

        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          《中国全球商品买卖网》已设计自己的品牌名称和logo，全球统一使用
        </p>

        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          《中国全球商品买卖网》未来将与世界各国知名电商平台链接合作、优势互补、资源共享、合作共赢
        </p>


        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          创建《中国全球商品买卖网》互联网电商科技产业园
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose pl-5 pr-5 pt-5">
          （1）创建电商设计研究院、电商培训学校、电商大学，为全球培养人才。
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose pl-5 pr-5 ">
          （2）创建电商短视频和直播基地。
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose pl-5 pr-5 ">
          （3）创建仓储基地。
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose pl-5 pr-5 ">
          （4）创建商品展示基地。
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose pl-5 pr-5 ">
          （5）创建物流运输基地。
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose pl-5 pr-5 ">
          （6）创建办公中心。
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose pl-5 pr-5 ">
          （7）创建生活园区。
        </p>


        <p class="mx-auto font-bold text-2xl  mt-8 text-left leading-loose flex items-center mb-2">
          <img src="@/assets/mmbuy/index/Star01.png" alt="Star" class="mr-2 w-6 h-6">
          《中国全球商品买卖网》每年召开一次全球电商节大会
        </p>
        <p class="index-desc bg-gray-100 mx-auto  text-xl text-left leading-loose p-5 ">

          将《中国全球商品买卖网》推向世界！将中国产品推向世界！让世界更加了解中国！让世界离不开中国！
        </p>

      </section>

      <!-- 创新和实践部分 end-->



      <!-- 前景 start-->
      <section class="text-center py-8">
        <h1 class=" text-4xl font-bold text-primary">《中国全球商品买卖网》未来发展前景无限美好</h1>
        <p class="mt-4 mx-auto index-desc lg:py-8 text-left leading-loose">
          这个平台的未来，想象空间无限！创意空间无限！设计空间无限！市场空间无限！实战空间无限！未来发展空间无限美好！
        </p>
        <div class=" flex flex-col lg:flex-row lg:justify-center lg:gap-0">
          <img src="@/assets/mmbuy/index/Mask09.png" alt="简介图片" class="mx-auto mb-4 lg:mb-0">

        </div>
      </section>
      <!-- 前景 end-->

      <!-- 建设 start-->
      <section class="text-center py-8">
        <h1 class=" text-4xl font-bold text-primary">《中国全球商品买卖网》的建设情况</h1>
        <p class="mt-8 mx-auto index-desc sm:py-4  leading-loose text-left">
          平台各项开发建设工作，正在紧锣密鼓中进行！平台各板块，将逐步上线运营。
        </p>

        <p class="mb-4 mx-auto index-desc   leading-loose text-left">
          我们深知，平台是个庞大的系统工程，不是我们一人、一家公司能做大做强的，必须争取政府的支持和参与！只有政府、企业、个人三方形成合力，才能做好这个世界级的互联网（供需）大平台。
        </p>
        <div class=" flex flex-col lg:flex-row lg:justify-center lg:gap-0">
          <img src="@/assets/mmbuy/index/Mask10.png" alt="简介图片" class="mx-auto mb-4 lg:mb-0">

        </div>
      </section>
      <!-- 建设 end-->

      <h1 class=" text-4xl font-bold text-primary">注明</h1>

      <p class="  mx-auto index-desc text-xl text-left leading-loose pl-5 pr-5 pt-5">
        1.《中国全球商品买卖网》2023年1月17日已在香港注册成功。已下发营业执照，公司法人：李京。
      </p>

      <p class="  mx-auto index-desc text-xl text-left leading-loose pl-5 pr-5 pt-3">
        2.《二十四小时买卖网（大连）科技有限公司》，公司法人：李京。
      </p>

      <p class="  mx-auto index-desc text-xl text-left leading-loose pl-5 pr-5 pt-3">
        3.《中国全球商品买卖网》（香港注册）和《二十四小时买卖网（大连）科技有限公司》两个公司同时运营，为了在国内开展工作方便，国内一切运营均使用《二十四小时买卖网（大连）科技有限公司》的营业执照。
      </p>

    </div>

  </div>
  <div class="w-full">
    <img src="@/assets/mmbuy/index/bottom.jpg" alt="简介图片" class="mx-auto mb-4 lg:mb-0">
  </div>

  <!-- Main section end -->
</template>

<script>
export default {

}
</script>

<style>
.index-title {
  font-family: Microsoft YaHei;
  font-size: 48px;
  font-weight: 700;
  line-height: 63.35px;
  color: #BF242A;
}

.index-desc {
  font-family: Microsoft YaHei;
  font-size: 26px;
  text-indent: 2em;
  line-height: 40px;
}

.index-serve {
  font-family: Microsoft YaHei;
  font-size: 25px;
  line-height: 40px;
}

.red-dot-list {
  list-style-type: none;
  /* 移除默认的列表项符号 */
  padding-left: 0;
  /* 移除默认的内边距 */
}

.red-dot-list li {
  display: flex;
}

.red-dot-list li::before {
  content: '•';
  /* 使用红点 */
  color: #BF242A;
  /* 设置红点颜色 */
  font-size: 1.2em;
  /* 设置红点大小 */
  margin-right: 0.5em;
  /* 设置红点和文本之间的间距 */
}
</style>
