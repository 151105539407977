import CryptoJS from "crypto-js";

// const crypto = require('crypto'); // 引入crypto模块

// 获取md5加密信息
// export function getMD5(info) {
//     return CryptoJS.MD5(info).toString();

// }
// export function getMD5(info) {

//       const md5 = crypto.createHash('md5');
//       md5.update(info, 'utf8');
//       const encryption = md5.digest();

//       let strBuf = '';
//       for (let i = 0; i < encryption.length; i++) {
//           const hex = (encryption[i] & 0xff).toString(16);
//           if (hex.length === 1) {
//               strBuf += '0' + hex;
//           } else {
//               strBuf += hex;
//           }
//       }

//       return strBuf;

// }
export function getMD5(info) {
  return CryptoJS.MD5(info).toString();
}

export function keySort(data) {
//   if (data) {
//     const keys = Object.keys(data);
//     keys.sort();

//     const queryString = keys
//       .filter((key) => key !== "sign")
//       .map((key) => `${key}=${data[key]}`)
//       .join("&");

//     console.log("Request-Signature:--->", queryString);
//     return queryString;
//   } else {
//     return "";
//   }

if (data) {  
    const keys = Object.keys(data).sort();  
    let queryString = '';  
    keys.forEach((key, index) => {  
      if (key !== 'sign') {  
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;  
        if (index < keys.length - 1) {  
          queryString += '&';  
        }  
      }  
    });  
    // 这里可以使用Vue的console.log替代，或者根据你的项目配置进行日志记录  
    // console.log("Request-Signature: " + queryString);  
    return queryString;  
  } else {  
    return '';  
  }  
}
