import request from '@/api/request'
import netModel from "@/api/requests";

// 提交资产信息
export function submitProperty(data) {
  return request({
    url: '/intention/saveintention',
    method: 'post',
    data: data
  })
}

// 获取资产列表
export function propertyList(data) {
  return netModel("/goods/getlist", "post", data);
}

// 获取资产类型
export function propertyTab(data) {
  return netModel("/goods/getcategory", "post", data);
}

// // 提交资产信息
// export function submitProperty(data) {
//   return NetModel({
//     url: '/intention/saveintention',
//     method: 'post',
//     data: data
//   })
// }

// // 获取资产列表
// export function propertyList(data) {
//   return NetModel(
//     url: '/goods/getlist',
//     method: 'post',
//     data: data
//   )
// }

// 查询定时任务调度列表
// export function listJob(query) {
//   return request({
//     url: '/monitor/job/list',
//     method: 'get',
//     params: query
//   })
// }

// // 查询定时任务调度详细
// export function getJob(jobId) {
//   return request({
//     url: '/monitor/job/' + jobId,
//     method: 'get'
//   })
// }

// // 新增定时任务调度
// export function addJob(data) {
//   return request({
//     url: '/monitor/job',
//     method: 'post',
//     data: data
//   })
// }

// // 修改定时任务调度
// export function updateJob(data) {
//   return request({
//     url: '/monitor/job',
//     method: 'put',
//     data: data
//   })
// }

// // 删除定时任务调度
// export function delJob(jobId) {
//   return request({
//     url: '/monitor/job/' + jobId,
//     method: 'delete'
//   })
// }

// // 导出定时任务调度
// export function exportJob(query) {
//   return request({
//     url: '/monitor/job/export',
//     method: 'get',
//     params: query
//   })
// }

// // 任务状态修改
// export function changeJobStatus(jobId, status) {
//   const data = {
//     jobId,
//     status
//   }
//   return request({
//     url: '/monitor/job/changeStatus',
//     method: 'put',
//     data: data
//   })
// }

// export function runJob(jobId, jobGroup) {
//   const data = {
//     jobId,
//     jobGroup
//   }
//   return request({
//     url: '/monitor/job/run',
//     method: 'put',
//     data: data
//   })
// }
