import axios from "axios";
 

const baseURL = "https://spapi.qqspmmw.com";
axios.defaults.headers["Content-Type"] = "multipart/form-data";

 
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  //   baseURL: process.env.VUE_APP_BASE_API,
  baseURL: baseURL,
  // 超时
  timeout: 10000,
});
// request拦截器
service.interceptors.request.use(
  (config) => {
   
    


// 是否需要设置 token
// const isToken = (config.headers || {}).isToken === false;
// if (getToken() && !isToken) {
//   config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
// }
    return config;
  },
  (error) => {
    console.log("请求出错了：" + error);
     


    return Promise.reject(error);
  }
);

// // 响应拦截器
service.interceptors.response.use(
  (res) => {
  

    // 未设置状态码则默认成功状态
    const code = res.data.code;
    // 获取错误信息
    const msg = res.data.msg;
    if (code !== 200) {
      return Promise.reject(msg);
    } else {
      return res.data;
    }
  },
  (error) => {
   

    console.log("err" + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }

    return Promise.reject(error);
  }
);

export default service;
