import axios from "axios";
import { getMD5, keySort } from "@/util/util";

 


const baseURL = "https://spapi.qqspmmw.com";
const appid = "86ab326ccfa232e2";
const secret = "9d0c2f72cb3534d2fd12b4c5678fe45d";

// 创建一个全局的 Axios 实例
const service = axios.create({
  baseURL,
  timeout: 10000,

  // headers: {
  //   "Content-Type": "application/json; charset=UTF-8",
  // },
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {

  

    const timestamp = Math.floor(Date.now() / 1000).toString();
    const access = getMD5(config.api + getMD5(appid + timestamp) + secret);

    let requestSignature = "";
    if (config.data) {
      requestSignature = getMD5(getMD5(keySort(config.data)) + timestamp);
    } else {
      requestSignature = getMD5("" + timestamp);
    }

    config.headers["Api"] = config.api;  
    config.headers["Appid"] = appid;
    config.headers["Timestamp"] = timestamp;
    config.headers["Access-Signature"] = access;
    config.headers["Request-Signature"] = requestSignature;

    return config;
  },
  (error) => {
 


    console.log("请求出错了：" + error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
 


    console.log("res--->",res);
    // 未设置状态码则默认成功状态
    const code = res.data.code;
    // 获取错误信息
    const msg = res.data.msg;
    if (code !== 200) {
      return Promise.reject(msg);
    } else {
      return res.data;
    }
  },
  (error) => {
 


    console.log("err" + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }

    return Promise.reject(error);
  }
);


 
export default async function NetModel(api, method, data) {  
  const url = `${baseURL}${api}`;  
  const config = {  
    api,
    method,  
    url,  
    data  
  };  
  
  try {  
    const response = await service(config);  
    return response.data;  
  } catch (error) {  
    console.error("请求失败：", error);  
    throw error;  
  }  
}


