<template>

<meta name="viewport" content="width=device-width, initial-scale=1.0">

  <!--  <div id="app">-->
  <!-- Navbar start -->
  <navbar-element v-if="hiddenNav"></navbar-element>
  <!-- Navbar end -->

  <router-view />


  <!-- Footer start -->
  <footer-element v-if="hiddenNav"></footer-element>
  <!-- Footer end -->

  <!--  </div>-->
</template>

<script>
import NavbarElement from '@/components/NavbarElement.vue';
import FooterElement from '@/components/FooterElement.vue';




export default {
  name: 'App',
  components: {
    FooterElement,
    NavbarElement,
    

  },
  data() {
    
  },
  

  computed: {
    hiddenNav() {
      return this.$route.meta.hiddenNav !== true
    },

  },
  methods: {
   
  }
}
</script>

<style></style>
