<template>
	<div>
		<section class="  px-0 lg:px-0 pt-28 mx-auto">
			<img src="@/assets/mmbuy/banner/banner03.png" alt="" class="w-full h-auto ">
		</section>

		<!-- Main section start -->
		<div v-if="index == 2">

			<sell-section></sell-section>
		</div>

		<div v-else>
			<asset-section></asset-section>

		</div>

		<!-- Main section end -->




	</div>

</template>

<script>
import SellSection from '@/sections/SellSection.vue';
import AssetSection from '@/sections/AssetSection.vue';


export default {
	name: 'PropertyInfo',
	components: {
		SellSection,
		AssetSection
	},
	created() {

		if (this.$route.params.index !== undefined && this.$route.params.index !== '') {
			
			this.index = this.$route.params.index;
		}

		// if (this.$route.query.index !== undefined && this.$route.query.index !== '') {
		// 	console.log("this.$route.query.index",this.$route.query.index);
		// 	this.index = parseInt(this.$route.query.index, 2); // 确保 index 是整数  
		// }


	},
	data() {
		return {
			index: 1 
		};
	},
	updated() {
		// if (this.$route.params.index !== undefined && this.$route.params.index !== '') {
		// 	console.log("this.$route.params", this.$route.params);
		// 	this.index = this.$route.params.index;
		// }
	},
	watch: {

		'$route.params.index'(index) {
			// console.log("activeTab",newValue);
			this.index = index;   
		}
	},
}
</script>

<style scoped></style>
